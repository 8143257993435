import engineState from '@comparacar/components/src/state/engine'
import { iMainProps } from '@comparacar/lib/src/nextCommon/pages/assinar'
import React, { ReactElement } from 'react'
import { useSetRecoilState } from 'recoil'
export { getStaticProps } from '@comparacar/lib/src/nextCommon/pages/assinar'

import Layout from '../../template/Layout'
import Main from '../../template/Main'
import type { NextPageWithLayout } from '../_app'

const Overview: NextPageWithLayout<iMainProps> = ({ links, videoList, highlights }) => {
  const setEngine = useSetRecoilState(engineState)
  setEngine('subscribe')

  return <Main searchOption="subscribe" videoList={videoList} links={links} highlights={highlights} />
}

Overview.getLayout = function getLayout(page: ReactElement) {
  return <Layout>{page}</Layout>
}

export default Overview
